// This file is automatically generated by rake task 'locale:po_to_json'
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.es.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ko.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.zh-CN.min.js');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.zh-TW.min.js');
